import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {Typography, Box} from '@material-ui/core'; 
import { SelectInput, showNotification, useDataProvider, usePermissions } from 'react-admin';
import { Permissions } from '../permissions/permissions';

export function SelectRoutingAccount(props) {
	const [state, setState] = useState("INITIAL");
	const [accounts, setAccounts] = useState([]);
	const dataProvider = useDataProvider();
	const { permissions } = usePermissions();
	const dispatch = useDispatch();

	const hubId = props.record.id;

	useEffect(() => {
		if (state === "INITIAL") {
			setState("FETCHING_DATA");
			dataProvider(
				"GET_OFF_RESOURCE",
				Permissions.hub.feature + "/routingAccounts"
			)
				.then((message) => {
					setState("FETCHED");
					setAccounts(message.data);
				})
				.catch((error) => {
					setState("ERROR_FETCHING_DATA");
					dispatch(
						showNotification(
							"Errore durante la fetch dei tipi",
							"error"
						)
					);
				});
		}
	}, [dataProvider, dispatch, hubId, state]);

	if (
		!permissions ||
		!permissions.includes(
			`${Permissions.hub.feature}-${Permissions.hub.actions.ReadAll}`
		)
	) {
		return null;
	}

	if (!props.label || !props.source) {
		console.error("TypeFilter: label and source must be defined");
		return null;
	}

	if (state !== "FETCHED") {
		return null;
	}

	if (props.show === true) {
		const routingAccountId = props.record.routingAccountId;
		if (routingAccountId) {
			return (
				<Typography variant="caption">
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "4px",
							margin: "10px 0",
						}}
					>
						<span style={{ color: "rgba(0, 0, 0, 0.54)" }}>
							{props.label || "Routing account"}
						</span>
						<span style={{fontSize:"0.875rem"}}>
							{accounts.find(
								(account) => account.id === routingAccountId
							)?.name || "-"}
						</span>
					</Box>
				</Typography>
			);
		} else {
			return null;
		}
	} else {
		return <SelectInput {...{ ...props, choices: accounts }} />;
	}
}