import React, { useEffect, useState } from "react";
import { Permissions } from '../../../../permissions/permissions';
import { showNotification, useDataProvider, usePermissions, useRefresh } from "react-admin";
import { useDispatch } from "react-redux";
import { GET_OFF_RESOURCE } from "../../../../providers/nestjs_crud";
import { Button } from '@material-ui/core';

export function ForceBackToBePlannedButton(props) {
	const refresh = useRefresh();
    const dispatch = useDispatch()
    const dataProvider = useDataProvider();
	const { permissions } = usePermissions();
	const [status, setStatus] = useState("INITIAL")

	const id = props.record.id ? props.record.id: null;

	useEffect(() => {
	  if(status === "SEND"){
		setStatus("SENDING");
		dataProvider(GET_OFF_RESOURCE, `${Permissions.missionV2.feature}/${id}/${Permissions.missionV2.actions.ForceBackToBePlanned}`)
			.then(({ data }) => {
				if(data.success){
					dispatch(showNotification(`Completed`));
				}else{
					dispatch(showNotification(`Error: ${data.error}`, 'error'));
				}
				setStatus("DONE_TRANSITION");
				refresh();
			})
			.catch(error => {
				dispatch(showNotification(typeof error === 'string' ? error : JSON.stringify(error), 'warning'));
				setStatus("ERROR_TRANSITION");
			})
	  }

	}, [dataProvider, dispatch, id, props.resource, refresh, status])
	

	if (
		!permissions 
		|| (!permissions.includes(
			`${Permissions.missionV2.feature}-${Permissions.missionV2.actions.ForceBackToBePlanned}`
		)) 
		|| (!["PLANNING"].includes(
			props.record.stateMachine?.status
		))
	) {
		return null;
	}
	return (
		<Button
			size="medium"
			style={{ marginTop: "12px", backgroundColor: "red" }}
			variant="contained"
			color="primary"
			onClick={()=>setStatus("SEND")}
		>
			Forza: BACK_TO_BE_PLANNED
		</Button>
	);
}
