import React, { useState, useEffect } from "react";
import {
	Create,
	NumberInput,
	SimpleForm,
	TextInput,
	ReferenceInput,
	required,
	AutocompleteInput,
	useDataProvider,
	showNotification,
	DateInput,
} from "react-admin";
import BaseCreate from "../../../../inheritance/BaseCreate";
import { Permissions } from "../../../../../permissions/permissions";
import {
	Button,
	Toolbar,
	Dialog,
	DialogContent,
	DialogActions,
	DialogContentText,
	CircularProgress,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-final-form";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RepeatOptionInput } from "../Components/RepeatOptionInput";
import { TimeInput } from "../Components/TimeInput";

// function getDateString(dateInput) {
// 	const date = new Date(dateInput);

// 	return `${date.getFullYear()}-${(date.getMonth() + 1)
// 		.toString()
// 		.padStart(2, "0")}-${date.getDate()}`;
// }

function CustomToolbar(props) {
	let history = useHistory();

	const dataProvider = useDataProvider();
	const dispatch = useDispatch();

	const form = useForm();

	const [state, setState] = useState("INITIAL");
	const [existingMissions, setExistingMissions] = useState([]);

	const disableButtonState = [
		"FETCH_DATA",
		"FETCHING_DATA",
		"EXISTING_MISSIONS",
	];

	useEffect(() => {
		if (state === "FETCH_DATA") {
			setState("FETCHING_DATA");

			const formState = form.getState();
			const filter = {
				"placeId||eq": formState.values.placeId,
				"stateMachine.status||notin": ["CANCELLED", "ERROR"],
				"active||eq": true,
				"type||eq": formState.values.type,
				"startDate||lte": formState.values.startDate,
			};

			switch (formState.values.type) {
				case "weekly":
					filter["weekday||eq"] = formState.values.weekday;
					break;
				default:
					break;
			}

			dataProvider
				.getList(Permissions.recurringMission.feature, {
					filter,
					pagination: { page: 1, perPage: 2 },
				})
				.then((response) => {
					if (response.data.length > 0) {
						setState("EXISTING_MISSIONS");
						setExistingMissions(response.data);
					} else {
						form.submit();
					}
				})
				.catch((e) => {
					setState("INITIAL");
					const error = typeof e === "string" ? e : JSON.stringify(e);
					dispatch(showNotification(`Errore: ${error}`, "error"));
				});
		}
	}, [dataProvider, dispatch, state, form, history]);

	const handleSave = () => {
		const formState = form.getState();
		if (Object.keys(formState.errors).length === 0) {
			setState("FETCH_DATA");
		} else {
			form.submit();
		}
	};

	const handleGoToRecurringMissions = () => {
		if (existingMissions.length === 1) {
			history.push(
				`/${Permissions.recurringMission.feature}/${existingMissions[0].id}/show`
			);
			return;
		}

		const formState = form.getState();

		const filter = JSON.stringify({
			"stateMachine": {
				"status||notin": ["CANCELLED", "ERROR"],
			},
			"placeId||eq": formState.values.placeId,
			"active||eq": true,
			"type||eq": formState.values.type,
			"startDate||lte": formState.values.startDate,
			"endDate||gte": formState.values.endDate,
		});

		history.push({
			pathname: `/${Permissions.recurringMission.feature}`,
			search: new URLSearchParams({
				filter,
			}).toString(),
		});
	};

	return (
		<Toolbar {...props}>
			<Button
				disabled={disableButtonState.includes(state)}
				variant="contained"
				color="primary"
				startIcon={
					disableButtonState.includes(state) ? (
						<CircularProgress size={25} color="inherit" />
					) : (
						<SaveIcon />
					)
				}
				onClick={handleSave}
			>
				Save
			</Button>

			<Dialog
				fullWidth
				open={state === "EXISTING_MISSIONS"}
				onClose={() => {
					setState("INITIAL");
				}}
			>
				<DialogContent>
					<DialogContentText>
						<b>{"Warning: "}</b>
						{`${
							existingMissions.length === 1
								? "Esiste già una missione"
								: "Esistono già delle missioni"
						} nello stesso luogo per la data selezionata.`}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						onClick={() => {
							form.submit();
						}}
						color="primary"
					>
						{"Crea comunque"}
					</Button>
					<Button
						variant="contained"
						onClick={handleGoToRecurringMissions}
						color="primary"
					>
						{existingMissions.length === 1
							? "Vai alla missione"
							: "Vai alla lista missioni"}
					</Button>
				</DialogActions>
			</Dialog>
		</Toolbar>
	);
}

export function RecurringMissionCreate(props) {
	return (
		<Create debounce={1000} {...props}>
			<SimpleForm redirect="show" toolbar={<CustomToolbar />}>
				<ReferenceInput
					enableGetChoices={(val) => {
						return !!(val && val.name && val.name.length >= 3);
					}}
					validate={[required("Campo obbligatorio")]}
					label="Luogo"
					source="placeId"
					reference={Permissions.place.feature}
					filterToQuery={(searchText) => {
						if (!searchText)
							return {
								"hubId||notnull": null,
							};

						return {
							"name": searchText,
							"hubId||notnull": null,
						};
					}}
				>
					<AutocompleteInput
						shouldRenderSuggestions={(val) =>
							val && val.length >= 3
						}
						optionValue="id"
						optionText={(record) => {
							if (!record || !record.id) {
								return "cancel";
							}
							return `${
								record ? `${record.id} - ${record.name}` : ""
							}`;
						}}
					/>
				</ReferenceInput>
				<DateInput
					source="startDate"
					label="Data inizio ricorrenza"
					validate={required("Campo obbligatorio")}
				/>
				<DateInput
					source="endDate"
					label="Data fine ricorrenza"
				/>
				<TimeInput
					label="Orario massimo esecuzione"
					source="maxExecutionTime"
					initialValue='13:00'
				/>
				<RepeatOptionInput />
				<NumberInput
					defaultValue={0}
					validate={[required("campo obbligatorio")]}
					label="Valore missione"
					source="value"
				/>
				<TextInput label="Commento" source="comment" />
				<ReferenceInput
					enableGetChoices={(val) => {
						return !!(
							val &&
							val["mainUser.label"] &&
							val["mainUser.label"].length >= 3
						);
					}}
					filterToQuery={(searchText) => {
						if (!searchText) return {};

						return {
							"mainUser.label": searchText,
						};
					}}
					label="Corriere suggerito"
					source="suggestedDeliveryManId"
					reference={Permissions.deliveryMan.feature}
					allowEmpty
				>
					<AutocompleteInput
						shouldRenderSuggestions={(val) => {
							return val && val.length >= 3;
						}}
						optionText={(record) => {
							if (!record || !record.id) {
								return "cancel";
							}
							return `${
								record
									? `${record.mainUser.id} - ${record.mainUser.label}`
									: ""
							}`;
						}}
						optionValue="id"
					/>
				</ReferenceInput>
				<BaseCreate />
			</SimpleForm>
		</Create>
	);
}
