import React from "react";
import {
	Show,
	SimpleShowLayout,
	TextField,
	DateField,
	ReferenceField,
	EditButton,
	useRedirect,
} from "react-admin";
import { Permissions } from "../../../../../permissions/permissions";
import StateMachineReferenceField from "../../../../../components/StateMachineReferenceField";
import Aside from "../../../inheritance/BaseAsideWithUser";

import { Grid, Container, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StateMachineFieldWithActions } from "../../../../../components/StateMachineFieldWithActions";
import { RepeatOptionInput } from "../Components/RepeatOptionInput";
import RecurringJobList from "./job/RecurringJobList";
import { ExecuteForceButton } from "../Components/ExecuteForceButton";
import ListIcon from '@material-ui/icons/List';
import { TimeInput } from "../Components/TimeInput";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(1),
	},
	toolbar: {
		padding: theme.spacing(2),
		display: "flex",
		justifyContent: "flex-end",
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(0),
		top: theme.spacing(0),
		padding: theme.spacing(1),
	},
	dialogContent: {
		padding: 0,
		margin: "30px 30px 0",
	},
}));

export function RecurringMissionShow(props) {
	const classes = useStyles();
	return (
		<Container maxWidth="xl" className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xl={12} lg={12} md={12} xs={12}>
					<Show
						{...props}
						aside={<Aside props={props}></Aside>}
						actions={<CustomActions {...props} />}
					>
						<SimpleShowLayout>
							<ReferenceField
								link="show"
								label="Luoghi"
								source="placeId"
								reference={Permissions.place.feature}
							>
								<TextField source="name" />
							</ReferenceField>
							<DateField
								locales="it-IT"
								label="Data inizio ricorrenza"
								source="startDate"
								options={{ timeZone: "UTC" }}
							/>
							<DateField
								label="Data fine ricorrenza"
								locales="it-IT"
								source="endDate"
								options={{ timeZone: "UTC" }}
							/>
							<TimeInput
								show
								label="Orario massimo di inizio"
								source="maxExecutionTime"
							/>
							<RepeatOptionInput show />
							<TextField label="Valore missione" source="value" />
							<TextField label="Commento" source="comment" />
							<ReferenceField
								link="show"
								label="Corriere suggerito"
								source="suggestedDeliveryManId"
								reference={Permissions.deliveryMan.feature}
							>
								<TextField source="mainUser.label" />
							</ReferenceField>
							<ReferenceField
								link="show"
								label="Hub"
								source="hubId"
								reference={Permissions.hub.feature}
							>
								<TextField source="name" />
							</ReferenceField>
							<TextField
								label="Errore ricorrenza"
								source="error"
							/>
							<StateMachineReferenceField
								label="Stato"
								source="stateMachine"
								reference={`${Permissions.recurringMission.feature}_${Permissions.recurringMission.subFeatures.stateMachine.name}`}
							></StateMachineReferenceField>
							<StateMachineFieldWithActions
								label="Actions"
								reference={`${Permissions.recurringMission.feature}_${Permissions.recurringMission.subFeatures.stateMachine.name}`}
								source="stateMachineId"
							/>
							<ShowMissionsBtn />
						</SimpleShowLayout>
					</Show>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
					<RecurringJobList id={props.id} record={props.data} />
				</Grid>
			</Grid>
		</Container>
	);
}

function CustomActions({ basePath, data }) {
	const classes = useStyles();

	return (
		<div className={classes.toolbar}>
			{data && <ExecuteForceButton record={data} />}
			<EditButton basePath={basePath} record={data} />
		</div>
	);
}

function ShowMissionsBtn(props) {
	const redirect = useRedirect();
	const handleClick = () => {
		redirect(
			`/missionV2?displayedFilters={"recurringMissionId||eq":true}&filter={"recurringMissionId||eq":"${props.record.id}"}`
		);
	};
	return (
		<Button
			startIcon={<ListIcon />}
			variant="contained"
			style={{ marginTop: "10px" }}
			color="primary"
			onClick={handleClick}
		>
			Missioni Create
		</Button>
	);
}
