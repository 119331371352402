import React, { useEffect, useState } from "react";
import SyncIcon from '@material-ui/icons/Sync';
import { makeStyles } from '@material-ui/core/styles';
import { Permissions } from '../../../../permissions/permissions';
import { showNotification, useDataProvider, usePermissions, useRefresh } from "react-admin";
import { useDispatch } from "react-redux";
import { POST_OFF_RESOURCE } from "../../../../providers/nestjs_crud";
import { Button } from '@material-ui/core';

const style = makeStyles(() => ({
    syncActionBtn:{
        lineHeight:1,
    },
    syncAnimation:{
        animationName: '$rotate',
        animationDuration:"500ms",
        animationIterationCount:"infinite",
        animationTimingFunction:'linear',

    },
    '@keyframes rotate': {
        '0%': { transform: 'rotate(0deg)' }, 
        '100%': { transform: 'rotate(-360deg)' },
    }
}));


export function SyncButton(props) {
    const [state, setState] = useState('INITIAL')
	const classes = style();
    const hubId = localStorage.getItem('hubSelected');
    const hubSelected = hubId ? true : false;
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();
    const { permissions } = usePermissions();
    const refresh = useRefresh();

    useEffect(() => {
      if(state === 'SYNC'){
        setState('SYNCING')
        dataProvider(POST_OFF_RESOURCE, `${Permissions.missionV2.feature}/${Permissions.missionV2.actions.RoutingToolSync}`, {
            data: { hubId: hubId},
        }).then((data) => {
            if (data.data.success) {
                setState("INITIAL");
                dispatch(
                    showNotification(
                        `Sincronizzazione con tool di routing completata`,
                    )
                );
                refresh();
            }else{
                dispatch(
                    showNotification(
                        `Errore durante la sync dei dati: ${data.data.error}`,
                        "error"
                    )
                );
                setState("ERROR_SEND");
                refresh();
            }
        }).catch((error)=>{
            setState('SYNC_ERROR');
            dispatch(showNotification(`Errore durante la sync dei dati: ${error}`, 'error'));
        })
      }
    }, [dataProvider, dispatch, hubId, refresh, state])
    
    const handleSync = () => {
        setState('SYNC')
	};

    if (
		!permissions ||
		!permissions.includes(
			`${Permissions.missionV2.feature}-${Permissions.missionV2.actions.RoutingToolSync}`
		)
	) {
		return null;
	}

	return (
		<div {...props}>
			<Button
                disabled={!hubSelected || (hubSelected && state === 'SYNCING')}
				className={`${classes.syncActionBtn}`}
                color="primary"
				onClick={handleSync}
				startIcon={<SyncIcon className={`${state === 'SYNCING' && classes.syncAnimation}`}/>}
			>
				Sync routing
			</Button>
		</div>
	);
}