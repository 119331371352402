import React from 'react';
import BidirectionalForm from '../../../../components/BidirectionalForm/v2';
import { useLocation } from "react-router-dom";

const config = {
    type: {
        dependsOn: [],
        type: 'select',
        defaultLabel: 'Service Type',
        search: false,
    },
    subDestinationType: {
        dependsOn: ['type'],
        type: 'select',
        defaultLabel: 'Tipo destinazione',
    },
    sellingPointId: {
        dependsOn: ['type', 'subDestinationType'],
        type: 'select',
        search: true,
        defaultLabel: 'Punto di Vendita',
    },
    warehouseLocationId: {
        dependsOn: ['type', 'subDestinationType'],
        type: 'select',
        search: true,
        defaultLabel: 'Location di magazzino',
    },
    deliveryManSkills: {
        dependsOn: ['type'],   
        type: 'select',
        defaultLabel: 'Skills',
        search: true,
        multiple: true,
    },
    businessUnitId: {
        dependsOn: ['type'],
        type: 'select',
        defaultLabel: 'Business Unit',
        search: true,
    },
    durationMinutes: {
        type: 'number',
        dependsOn: ['type'],
        defaultLabel: 'Durata attesa del servizio in minuti',
    },
    maxAdvanceMinutes: {
        type: 'number',
        dependsOn: ['type'],
        defaultLabel: 'Anticipo massimo standard in minuti',
    },
    inventoryExecution: {
        dependsOn: ['type', 'subDestinationType'],
        type: 'select',
        defaultLabel: 'Inventario',
    },
    inventoryLotCount: {
        dependsOn: ['inventoryExecution', 'type'],
        type: 'select',
        defaultLabel: 'InventoryLotCount',
    },
    inventoryCycleCount: {
        dependsOn: ['inventoryExecution', 'type'],
        type: 'select',
        defaultLabel: 'InventoryCycleCount',
    },
    loadTransferExecution: {
        dependsOn: ['type', 'subDestinationType'],
        type: 'select',
        defaultLabel: 'Esecuzione transfer di carico'
    },
    unLoadTransferExecution: {
        dependsOn: ['type', 'subDestinationType'],
        type: 'select',
        defaultLabel: 'Esecuzione transfer di scarico'
    },
    transferInputType: {
        dependsOn: ['type', 'loadTransferExecution', 'unLoadTransferExecution'],
        type: 'select',
        defaultLabel: 'Transfer'
    },
    loadTransferId: {
        type: 'number',
        dependsOn: ['transferInputType', 'type', 'loadTransferExecution'],
        defaultLabel: 'Transfer di carico',
    },
    unLoadTransferId: {
        type: 'number',
        dependsOn: ['transferInputType', 'type', 'unLoadTransferExecution'],
        defaultLabel: 'Transfer di scarico',
    },
    loadLotLogic: {
        dependsOn: ['type', 'transferInputType', 'loadTransferExecution'],
        type: 'select',
        defaultLabel: 'Lot Logic Transfer di carico'
    },
    loadPriority: {
        dependsOn: ['type', 'transferInputType', 'loadTransferExecution'],
        type: 'select',
        defaultLabel: 'Priority Transfer di carico'
    },
    loadReason: {
        dependsOn: ['type', 'transferInputType', 'loadTransferExecution'],
        type: 'select',
        defaultLabel: 'Reason Transfer di carico'
    },
    loadType: {
        dependsOn: ['type', 'transferInputType', 'loadTransferExecution'],
        type: 'select',
        defaultLabel: 'Type Transfer di carico'
    },
    unLoadLotLogic: {
        dependsOn: ['type', 'transferInputType', 'unLoadTransferExecution'],
        type: 'select',
        defaultLabel: 'Lot Logic Transfer di scarico'
    },
    unLoadPriority: {
        dependsOn: ['type', 'transferInputType', 'unLoadTransferExecution'],
        type: 'select',
        defaultLabel: 'Priority Transfer di scarico'
    },
    unLoadReason: {
        dependsOn: ['type', 'transferInputType', 'unLoadTransferExecution'],
        type: 'select',
        defaultLabel: 'Reason Transfer di scarico'
    },
    unLoadType: {
        dependsOn: ['type', 'transferInputType', 'unLoadTransferExecution'],
        type: 'select',
        defaultLabel: 'Type Transfer di scarico'
    },
    transferItems: {
        type: 'form-google-sheet',
        dependsOn: ['type', 'transferInputType'],
    },
    executionComment: {
        type: 'text',
        dependsOn: [],
        defaultLabel: 'Commento per esecuzione'
    },
}

export default function JobCreate() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
	const missionId = params.get('missionId');

    return (
        <BidirectionalForm
            context={{
                operationType: 'create-job',
                missionId
            }}
            addData={(data) => {
                data.deliveryManSkills = data.deliveryManSkills ? data.deliveryManSkills.map((deliveryManSkill => {
                    return deliveryManSkill.value;
                })) : [];

                return data;
            }}
            config={config}
            src='missionV2/missionJob'
            path='missionJob/optionsForField'
            buttonTitle='Crea'
            title='Creazione Servizio Missione'
        />
    );
}