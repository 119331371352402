import React, { cloneElement, Fragment, useState } from "react";
import {
	Datagrid,
	List,
	TextField,
	DateField,
	ReferenceField,
	DateInput,
	usePermissions,
	TopToolbar,
	SelectInput,
} from "react-admin";
import { Button } from "@material-ui/core";
import { Permissions as OpsPermissions } from "../../../permissions";
import { Permissions } from "../../../../../permissions/permissions";
import Filter from "../../../../inheritance/BaseFilter";
import ScheduleIcon from "@material-ui/icons/Schedule";
import RestoreIcon from "@material-ui/icons/Restore";
import { ExecuteDialog } from "../Components/ExecuteDialog";

function RecurringMissionFilter(props) {
	return (
		<Filter {...props}>
			<SelectInput
				alwaysOn
				label="Stato"
				source="status||eq"
				choices={[
					{ id: "running", name: "Running" },
					{ id: "idle", name: "Idle" },
				]}
			/>
			<DateInput
				source={`lastExecutionCheckpoint||gte`}
				label={`Ultima Data Da`}
			/>
			<DateInput
				source={`lastExecutionCheckpoint||lte`}
				label={`Ultima Data A`}
			/>
		</Filter>
	);
}

export function RecurringMissionExecutionList(props) {
	return (
		<List
			{...props}
			filters={<RecurringMissionFilter />}
			sort={{ field: "created_at", order: "DESC" }}
			actions={<TopAppBarActions />}
			bulkActionButtons={false}
		>
			<Datagrid rowClick={false}>
				<ReferenceField
					link="show"
					label="Hub"
					source="hubId"
					reference={Permissions.hub.feature}
				>
					<TextField source="name" />
				</ReferenceField>
				<TextField label="Stato" source="status" />
				{/* <BooleanField label="In esecuzione" source="busy" /> */}
				<DateField
					locales="it-IT"
					label="Ultima data creata"
					source="lastExecutionCheckpoint"
				/>
			</Datagrid>
		</List>
	);
}

function TopAppBarActions(props) {
	return (
		<TopToolbar>
			<ButtonExecute />
			<ButtonExecuteCreateAgain />
			{cloneElement(props.filters, { context: "button" })}
		</TopToolbar>
	);
}

function ButtonExecute(props) {
	const hubId = localStorage.getItem("hubSelected");
	const [openDialog, setOpenDialog] = useState(false);
	const { permissions } = usePermissions();
	if (
		!permissions ||
		!permissions.includes(
			`${OpsPermissions.recurringMissionExecution.feature}-${OpsPermissions.recurringMissionExecution.actions.Execute}`
		)
	) {
		return null;
	}
	return (
		<Fragment>
			<Button
				startIcon={<ScheduleIcon />}
				color="primary"
				variant="text"
				size="small"
				disabled={!hubId}
				onClick={() => setOpenDialog(true)}
			>
				Crea visite
			</Button>
			<ExecuteDialog
				open={openDialog}
				onClose={() => setOpenDialog(false)}
			/>
		</Fragment>
	);
}

function ButtonExecuteCreateAgain(props) {
	const [openDialog, setOpenDialog] = useState(false);
	const { permissions } = usePermissions();
	const hubId = localStorage.getItem("hubSelected");

	if (
		!permissions ||
		!permissions.includes(
			`${OpsPermissions.recurringMissionExecution.feature}-${OpsPermissions.recurringMissionExecution.actions.ExecuteForce}`
		)
	) {
		return null;
	}

	return (
		<Fragment>
			<Button
				color="primary"
				startIcon={<RestoreIcon />}
				variant="text"
				size="small"
				onClick={() => setOpenDialog(true)}
				disabled={!hubId}
			>
				(Tech) Crea visite
			</Button>
			<ExecuteDialog
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				tech
			/>
		</Fragment>
	);
}
