import React, { useState } from "react";
import { useDispatch, useStore } from "react-redux";
import {
	DateInput,
	useDataProvider,
	SimpleForm,
	required,
	showNotification,
	useRefresh,
} from "react-admin";
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Typography,
	Box,
} from "@material-ui/core";
import { Permissions } from "../../../permissions";
import { POST_OFF_RESOURCE } from "../../../../../providers/nestjs_crud";

export function ExecuteDialog(props) {
	const [formData, setFormData] = useState({
		startDate: "",
		endDate: "",
	});
	const [error, setError] = useState("");
	const refresh = useRefresh();

	const dataProvider = useDataProvider();
	const dispatch = useDispatch();
	const store = useStore();

	const recurringMissionExecutionData =
		store.getState().admin.resources.recurringMissionExecution.data;
	const recurringMissionExecutionByHub = [];
	if (recurringMissionExecutionData) {
		Object.values(recurringMissionExecutionData).forEach((execution) => {
			recurringMissionExecutionByHub[execution.hubId] = execution;
		});
	}

	const formSubmit = () => {
		setError("");
		if(!formData.startDate || !formData.endDate) {
			setError("Compilare tutti i campi");
			return;
		}
		props.onClose();
		// invio dati per l'esecuzione

		let endpoint = `${Permissions.recurringMissionExecution.feature}/${Permissions.recurringMissionExecution.actions.Execute}`;
		if (props.tech) {
			endpoint = `${Permissions.recurringMissionExecution.feature}/${Permissions.recurringMissionExecution.actions.ExecuteForce}`;
		}

		dataProvider(POST_OFF_RESOURCE, endpoint, {
			data: {
				from: new Date(formData.startDate),
				to: new Date(formData.endDate),
				dateRange: {},
			},
		})
			.then((message) => {
				console.log(message);
			})
			.catch((e) => {
				dispatch(
					showNotification(
						`Errore durante la generazione dei dati: ${e}`,
						"error"
					)
				);
			});
		refresh();
	};

	if (props.open === undefined || props.onClose === undefined) {
		console.error(`Missing props: open, onClose`);
		return null;
	}

	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			scroll={"paper"}
			maxWidth="lg"
		>
			<DialogTitle>{props.tech && "(Tech) "} Crea visite</DialogTitle>
			<DialogContent>
				<SimpleForm toolbar={false}>
					<Box
						display="flex"
						sx={{
							flexDirection: "column",
							gap: "20px",
							marginTop: "10px",
						}}
					>
						<DateInput
							fullWidth
							locales="it-IT"
							onChange={(e) =>
								setFormData({
									...formData,
									startDate: e.target.value,
								})
							}
							validate={[
								required("campo obbligatorio"),
								(value) => {
									if (!value || !isNaN(new Date(value)))
										return;

									return "Data non valida";
								},
							]}
							label="Data di inizio"
							source="startDate"
						/>
						<DateInput
							fullWidth
							locales="it-IT"
							validate={[
								required("campo obbligatorio"),
								(value) => {
									if (!value || !isNaN(new Date(value)))
										return;

									return "Data non valida";
								},
							]}
							onChange={(e) =>
								setFormData({
									...formData,
									endDate: e.target.value,
								})
							}
							label="Data di fine"
							source="endDate"
						/>
					</Box>
				</SimpleForm>
			</DialogContent>
			<DialogActions>
				<Typography variant="body2" color="error">
					{error}
				</Typography>
				<Button onClick={formSubmit} color="primary">
					Crea
				</Button>
			</DialogActions>
		</Dialog>
	);
}
