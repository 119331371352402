import React, { useEffect, useState } from 'react';
import { List, Datagrid, TextField, ReferenceField, Filter, AutocompleteArrayInput, DateTimeInput, usePermissions, useDataProvider, useRefresh, DateField, showNotification } from 'react-admin';
import { Permissions } from '../../../../../permissions/permissions';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import AsyncSelect from 'react-select/async';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Dialog, DialogContent, DialogActions, DialogTitle, IconButton, Typography, Box } from '@material-ui/core'; 
import MUITextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { POST_OFF_RESOURCE } from '../../../../../providers/nestjs_crud';
import GoogleSheet from '../../../../../components/BidirectionalForm/GoogleSheet';
import { useDispatch } from 'react-redux';
import { TypeInput } from '../task/components/TypeInput';

const config = {
  type: {
      type: 'select',
      defaultLabel: 'Service Type',
      search: false,
  },
  loadTransferExecution: {
      type: 'select',
      defaultLabel: 'Esecuzione transfer di carico'
  },
  unLoadTransferExecution: {
      type: 'select',
      defaultLabel: 'Esecuzione transfer di scarico'
  },
  transferInputType: {
      type: 'select',
      defaultLabel: 'Transfer'
  },
  loadTransferId: {
      type: 'number',
      defaultLabel: 'Transfer di carico',
  },
  unLoadTransferId: {
      type: 'number',
      defaultLabel: 'Transfer di scarico',
  },
  loadLotLogic: {
      type: 'select',
      defaultLabel: 'Lot Logic Transfer di carico'
  },
  loadPriority: {
      type: 'select',
      defaultLabel: 'Priority Transfer di carico'
  },
  loadReason: {
      type: 'select',
      defaultLabel: 'Reason Transfer di carico'
  },
  loadType: {
      type: 'select',
      defaultLabel: 'Type Transfer di carico'
  },
  unLoadLotLogic: {
      type: 'select',
      defaultLabel: 'Lot Logic Transfer di scarico'
  },
  unLoadPriority: {
      type: 'select',
      defaultLabel: 'Priority Transfer di scarico'
  },
  unLoadReason: {
      type: 'select',
      defaultLabel: 'Reason Transfer di scarico'
  },
  unLoadType: {
      type: 'select',
      defaultLabel: 'Type Transfer di scarico'
  },
  transferItems: {
      type: 'form-google-sheet',
  },
  executionComment: {
    type: 'text',
    defaultLabel: 'Commento per esecuzione'
},
}

const baseNeededData ={
  loadTransferExecution: null,
  unLoadTransferExecution: null,
  transferInputType: null,
  loadLotLogic: null,
  loadPriority: null,
  loadReason: null,
  loadType: null,
  unLoadLotLogic: null,
  unLoadPriority: null,
  unLoadReason: null,
  unLoadType: null,
  transferItems: null,
  loadTransferId: null,
  unLoadTransferId: null,
  executionComment:null,
}

const formatDateOptions = {
  weekday: "short",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    padding: theme.spacing(1)
  },
  dialogContent: {
    padding: 0,
    margin: '30px 30px 0',
  },
  missing: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: 'red',
  },
  myForm:{
    display: 'flex',
    flexDirection: 'column',
    gap:"10px",
    margin: "20px 0px"
  }
}));

const JobTransferAssignmentFilter = (props) => (
    <Filter {...props}>
         <AutocompleteArrayInput
            label="STATO"
            source="mission.stateMachine.status||in"
            choices={[
                { id: 'PLANNED', name: 'PLANNED' },
                { id: 'PLANNING', name: 'PLANNING' },
                { id: 'TO_BE_PLANNED', name: 'TO_BE_PLANNED' },
            ]}
            translateChoice={false}
            allowEmpty={false}
            />
        <TypeInput label="Service Type" source="type||in" alwaysOn multiple/>
        <DateTimeInput
          alwaysOn
          source={`mission.estimatedStartDate||gte`}
          label={`data stimata Da`}
        />
        <DateTimeInput 
          alwaysOn
          source={`mission.estimatedStartDate||lte`}
          label={`data stimata A`}
        />
        <DateTimeInput 
          alwaysOn
          source={`mission.maxExecutionDate||gte`}
          label={`data massima di Esecuzione Da`}
        />
        <DateTimeInput
          alwaysOn
          source={`mission.maxExecutionDate||lte`}
          label={`data massima di Esecuzione A`}
        />
    </Filter>
);

export function JobTransferAssignmentList (props) {


    return (
      <List {...props}
        sort={{ field: 'ignore', order: 'ASC'} }
        filters={<JobTransferAssignmentFilter />}
        perPage={50}
        exporter={false}
        bulkActionButtons={false}>
        <Datagrid rowClick={null}>
          <TextField sortable={false} label="Transaction ID" source="transactionId" />
          <TextField sortable={false} label="Type" source="type" />
          <ReferenceField
            sortable={false} 
            label="Place"
            reference={`${Permissions.place.feature}`}
            source="mission.placeId"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Punto di Vendita"
            reference={`${Permissions.sellingPoint.feature}`}
            source="sellingPointId"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Warehouse Location"
            reference={`${Permissions.warehouseLocation.feature}`}
            source="warehouseLocationId"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <RangeExecutionDate label="Range di esecuzione"/>
          {/* <TextField sortable={false} label="Estimated Start Date" source="mission.estimatedStartDate" /> */}
          <DateField locales="it-IT" label="Estimated Start Date" source="mission.estimatedStartDate" options={formatDateOptions} showTime />
          <LoadTransfer label="Load" />
          <UnLoadTransfer label="UnLoad" />
          <TextField sortable={false} label="Mission Status" source="mission.stateMachine.status" />
          <Actions label="Azioni"/>
        </Datagrid>
      </List>
    )
}

function LoadTransfer(props) {
  if(props.record.loadTransferExecution === 'mandatory' && !props.record.loadTransferId) {
    return <Missing />
  }

  return (
    <ReferenceField
    reference={`${Permissions.transfer.feature}`}
    source="loadTransferId"
    link="show"
    >
      <TextField source="id" />
    </ReferenceField>
  );
}

function UnLoadTransfer(props) {
  if(props.record.unLoadTransferExecution === 'mandatory' && !props.record.unLoadTransferId) {
    return <Missing />
  }

  return (
    <ReferenceField
      reference={`${Permissions.transfer.feature}`}
      source="unLoadTransferId"
      link="show"
    >
      <TextField source="id" />
    </ReferenceField>
  );
}

function Missing() {
  const classes = useStyles();
  return <div className={classes.missing} />
}
  
export function Actions(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('INITIAL');
  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();
  const [ dto, setDto ] = useState({});
  const refresh = useRefresh();
  const [selectedData, setSelectedData] = useState(baseNeededData)
  const dtoData = dto.data?.dto || {};
  const needData = dto.data?.needData || {};

  const dispatch = useDispatch()


  useEffect(() => {
    if(status === 'FETCH_DATA') {
      setStatus('FETCHING_DATA');
      dataProvider(POST_OFF_RESOURCE, `${Permissions.missionJobTransferAssignment.feature}/${props.record.id}/config`, {
        data: {
          loadTransferExecution:selectedData.loadTransferExecution,
          unLoadTransferExecution:selectedData.unLoadTransferExecution,
          transferInputType:selectedData.transferInputType,
          executionComment:selectedData.executionComment,
        }
      })
        .then(message => {
              setDto(message);
              updateSelectedData(message.data?.needData)
              setStatus('DATA_FETCHED');

        })
        .catch((e) => {
          dispatch(showNotification("Errore durante la generazione dei dati", 'error'));
          setStatus('INITIAL');
        });
    }
    
    if(status === 'SEND_DATA') {
      setStatus('SENDING_DATA');
      const data2Send = {...baseNeededData};
      for (const key in data2Send) {
        if(selectedData[key]) {
          data2Send[key] = selectedData[key];
        } else if(dto.data.dto[key]) {
          data2Send[key] = dto.data.dto[key];
        }
      }

      if(selectedData.executionComment === ""){
        data2Send.executionComment = null;
      }
      
      if(data2Send.loadTransferExecution === 'not-show'){
        data2Send.loadTransferId = null;
      }

      if(data2Send.unLoadTransferExecution === 'not-show'){
        data2Send.unLoadTransferId = null;
      }

      dataProvider(POST_OFF_RESOURCE, `${Permissions.missionJobTransferAssignment.feature}/${props.record.id}`, {
        data: data2Send
      })
        .then(message => {
              setDto(message);
              setOpen(false);
              setStatus('INITIAL');
              refresh();
        })
        .catch((error) => {
          dispatch(showNotification(error, 'error'));
          setStatus('INITIAL');
        });
    }

  }, [dataProvider, dto, props.record.id, status, setDto, selectedData, refresh, dispatch]);

  if (!permissions || !permissions.includes(`${Permissions.missionJobTransferAssignment.feature}-${Permissions.missionJobTransferAssignment.actions.Assignment}`)) {
    return null;
  }

  function updateSelectedData(data=null){
    if(!data){
      setSelectedData(baseNeededData);
    }else{
      let tmpSelectedData = {...baseNeededData};
      for (const key in data){
        if(config[key].type !== 'select'){
          tmpSelectedData[key] = data[key]?.value;
        }else{
          tmpSelectedData[key] = data[key]?.values?.find(opt => opt.selected === true)?.id || null;
        }
      }

      setSelectedData(tmpSelectedData);
    }
  }

  function handleChange(value, key) { 
    setSelectedData(data =>{
      data[key] = value;
      return {...data};
    })


    if(['transferInputType', 'loadTransferExecution', 'unLoadTransferExecution'].includes(key)){
      setStatus('FETCH_DATA')
    }
  }

  return (
    <>
      <Button color="primary" variant="text" startIcon={<AddIcon />} onClick={() => {
        setOpen(true);
        setStatus('FETCH_DATA')
      }}>
          {props.title || "AGGIUNGI"}
      </Button>
      <Dialog
          open={open}
          onClose={() => {setOpen(false); updateSelectedData();}}
          scroll={'paper'}
          maxWidth='lg'
          fullWidth={true}
        >
          <DialogTitle id="scroll-dialog-title">{props.record.loadTransferId || props.record.unLoadTransferId ? "Modifica Transfer" : "Aggiungi Transfer"}</DialogTitle>
          <IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={() =>{ setOpen(false); updateSelectedData();}}>
            <CloseIcon />
          </IconButton>
          <DialogContent dividers={true} className={classes.dialogContent}>
            <FormControl className={classes.myForm}>
              <Place placeId={dto.data?.dto?.mission?.placeId}/>
              <TextBox label="Service Type" value={dtoData.type}/>
              {dtoData.sellingPointId && 
                <TextBox label="Selling Point" value={`${dtoData.sellingPointId} - ${dtoData.sellingPointLabel}` }/>
              }
              {dtoData.warehouseLocationId && 
                <TextBox label="Warehouse Location" value={`${dtoData.warehouseLocationId} - ${dtoData.warehouseLocationLabel}` }/>
              }
              {(Object.keys(needData)).map(key => {
                if(needData[key].hide){
                  return null;
                }
                if(['number', 'text'].includes(config[key].type)){
                  return <MUITextField
                      key={key}
                      label={config[key].defaultLabel}
                      value={selectedData[key] || ""}
                      type={config[key].type}
                      disabled={needData[key].disabled}
                      onChange={(e) => handleChange(config[key].type === 'text' ? e.target.value : parseInt(e.target.value), key)}
                      placeholder={'Digita il testo'}
                  />
                } else if(config[key].type === 'form-google-sheet'){
                  return <GoogleSheet
                    key={key}
                    helperText={needData[key].helperText}
                    delimiter={needData[key].delimiter}
                    columns={needData[key].columns}
                    values={selectedData[key]}
                    onChange={(values) => handleChange(values, key)}
                  />
                }else if(config[key].type === 'select'){

                  let options = needData[key].values.map(opt => {return {value:opt.id, label:opt.name, selected: opt.selected}});

                  return (
                    <div key={key}>
                      <Typography gutterBottom={config[key].type === 'select' || (config[key].type !== 'select' && (!needData[key].formData && needData[key].awaitingDependency))}>{key}</Typography>
                      <AsyncSelect 
                        isMulti={config[key].multiple}
                        isSearchable={config[key].search === true}
                        isClearable={config[key].search === true}
                        isDisabled={needData[key].disabled}
                        defaultOptions={options}
                        value={
                          // controllare quale sia quello che mi arriva
                          selectedData[key] ? 
                            options.find(opt => opt.value === selectedData[key])
                            : (dtoData[key] ? 
                                options.find(opt => opt.value === dtoData[key]) 
                                : options.find(opt => opt.selected))
                        }
                        onChange={(e, action) => {
                          handleChange(e ? e.value : null, key);
                        }}
                      />
                    </div>
                  )
                } else {
                  return null;
                }
              }
              )}
            </FormControl>

            
            <DialogActions>
              <Button variant='contained' startIcon={<AddIcon />} onClick={() => {
                setStatus('SEND_DATA')
              }} color='primary'>
                Aggiungi
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </>
  );
}

function Place(props) {
	const [state, setState] = useState("INITIAL");
	const [place, setPlace] = useState({ id: "", name: "" });
	const dataProvider = useDataProvider();

	useEffect(() => {
		if (props.placeId && state === "INITIAL") {
			setState("FETCH_DATA");
			dataProvider
				.getOne(Permissions.place.feature, { id: props.placeId })
				.then((data) => {
					setPlace(data.data);
					setState("FETCHED");
				});
		}
	}, [dataProvider, props, state]);

	return (
		<Typography variant="caption">
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<span style={{ fontWeight: "bold" }}>Place: </span>
				<MUITextField
					value={`${place.id} - ${place.name}`}
					type={"text"}
					disabled={true}
				/>
			</Box>
		</Typography>
	);
}

function RangeExecutionDate(props) {
	try {
		const maxExecutionDate = new Date(
			props.record.mission.maxExecutionDate
		);
		const maxAdvanceMinutes = props.record.mission.maxAdvanceMinutes;
		const minExecutionDate = new Date(
			maxExecutionDate.getTime() - maxAdvanceMinutes * 60000
		);

		return (
			<Typography variant="caption">
				<p>
          {new Intl.DateTimeFormat("it-IT", formatDateOptions).format(minExecutionDate)}
					<span>&#32;&rarr;&#32;</span>
					{new Intl.DateTimeFormat("it-IT", formatDateOptions).format(maxExecutionDate)}
				</p>
			</Typography>
		);
	} catch (e) {
		console.log(e.message);
		return null;
	}
}

function TextBox(props){

  const type = props.type || "text";

  return( 
    <Typography variant="caption">
      <Box sx={{"display":'flex', flexDirection:"column"}}>
        {props.label && <span style={{ fontWeight: "bold" }}>{props.label}: </span>}
        {type === "text" && props.value && <DataField disabled={true} value={props.value}/>}
        {type === "sourced" && 
          <div style={{display:"flex", alignItems:"center", gap: "5px"}}>
            <TextField sortable={false} label="" source={props.source} reference={props.reference}/>
            <p style={{margin:"0"}}>-</p>
            <ReferenceField
              sortable={false} 
              label=""
              reference={props.reference}
              source={props.source}
              link=""
              >
              <TextField source="name" />
            </ReferenceField>
          </div>
        }
        {type === "link" && props.source && props.reference && 
            <ReferenceField
                sortable={false} 
                label=""
                reference={props.reference}
                source={props.source}
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
        }
        
      </Box>
    </Typography>
  );
}

function DataField(props) {
  const [value, setValue] = useState(props.value);
  const [status, setStatus] = useState('INITAL');

  useEffect(() => {
      if (status === 'SEND_VALUE') {
          if (value || (props.value !== value)) {
              // props.onChange(value, props.valueKey);
          } else {
              setStatus('INITIAL')
          }
      }
  }, [status, props, value]);


  return (
      <MUITextField
          value={value}
          type={props.type || 'text'}
          // openPopup={['date', 'datetime-local'].includes(props.type)}
          onFocus={() => { setStatus('FOCUS') }}
          onBlur={() => setStatus('SEND_VALUE')}
          disabled={props.disabled}
          onChange={(e) => setValue(e.target.value)}
          placeholder={['date', 'datetime-local'].includes(props.type) ? '' : 'Digita il testo'}
          InputProps={{
              [['date', 'datetime-local'].includes(props.type) ? 'startAdornment' : 'endAdornment']: status === 'FOCUS' ? <InputAdornment position={['date', 'datetime-local'].includes(props.type) ? 'start' : 'end'}>
                  <Button onClick={() => { setStatus('SEND_VALUE') }} variant="contained" color="primary" size="small" endIcon={<CheckCircleOutlineIcon />}>
                      DONE
                  </Button>
              </InputAdornment> : null
          }}
      />
  );
}
